import React from "react"
import ReactHtmlParser from "html-react-parser"
import { Link } from "gatsby"
import footer from "../../assets/img/new/tlo-serch-04-3.webp"
import { all_articles } from "../../components/ArticlesList"
import article_icon from "../../assets/img/ikonki/strony/artykul_white.svg"
import "./style.css"

const Article = props => {
  let articles_random = [...all_articles]

  const shuffleArray = array => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  }

  articles_random = articles_random.filter(elem => {
    return elem.slug !== props.slug
  })

  shuffleArray(articles_random)

  articles_random = articles_random.slice(0, 2)

  return (
    <div className="article" itemScope itemType="https://schema.org/Article">
      <div className="container-small">
        <div
          className={`image-wrapper${props.alignTop ? " align-top" : ""}`}
          itemProp="image"
        >
          {props.img}
        </div>
        <div className="container" itemProp="articleBody">
          <div className="row">
            <div className="col-12">{props.children}</div>
          </div>
        </div>
      </div>
      <div
        className="article-footer"
        style={{
          background: `url(${footer}) center center`,
          backgroundSize: "cover",
        }}
        itemProp="pageEnd"
      >
        <div className="container-small">
          <div className="container">
            <div className="row">
              <div className="col-12" style={{ padding: "30px 15px" }}>
                <p>
                  Nasz audyt SEO to oszczędność czasu i pieniędzy oraz cenna
                  podpowiedź, co musisz poprawić, żeby Twoja strona WWW
                  osiągnęła lepsze wyniki w wyszukiwarce.
                </p>
                <p className="text-center">
                  <Link
                    to={"/"}
                    className="btn btn-primary btn-huge-download call-to-action"
                    style={{ width: "auto" }}
                  >
                    Wykonaj darmowy audyt
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-small">
        <h2 className="text-center articles-random-header">Przeczytaj także</h2>
        <div className="articles-random">
          {articles_random.map((article, index) => (
            <div className="article-random" key={index}>
              <Link to={article.slug}>
                <img src={article.image} alt={article.name} />
              </Link>
              <div className="description">
                <h3>
                  <Link to={article.slug}>{article.name}</Link>
                </h3>
                <p>{ReactHtmlParser(article.excerpt)}</p>
                <p className="button-wrapper">
                  <Link
                    to={article.slug}
                    className="btn btn-primary btn-orange-hover"
                  >
                    <img
                      src={article_icon}
                      alt={"Artykuł - ikonka"}
                      style={{
                        transform: "translateX(-8px) translateY(-1px)",
                      }}
                    />{" "}
                    Czytaj więcej
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Article

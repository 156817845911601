import React from "react"
import { Link } from "gatsby"

const CallToAction = props => {
  return (
    <Link
      to={props.to}
      className="btn btn-primary btn-huge-download call-to-action"
    >
      {props.text}
    </Link>
  )
}

export default CallToAction

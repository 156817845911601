import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Kod Google Analytics. Do czego służy i w jaki sposób zbiera dane?",
        description:
          "Dowiedz się, na jakiej zasadzie działa kod Google Analytics, jak z niego korzystać, czym jest kod śledzenia oraz sprawdź, jak wykorzystać GA w kontekście SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/co-daje-zainstalowanie-kodu-google-analytics/",
                name: "Kod Google Analytics. Do czego służy i w jaki sposób zbiera dane?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/co-daje-zainstalowanie-kodu-google-analytics",
            text: "Co daje zainstalowanie kodu Google Analytics?",
          },
        ]}
      />
      <Article
        img={<img src={data.file.childImageSharp.fluid.src} alt={"Co daje zainstalowanie kodu Google Analytics?"} />}
      >
        <h1>Co daje zainstalowanie kodu Google Analytics?</h1>
        <p>
          <strong>
            Aplikacje analityczne są przydatnym narzędziem, służącym do sprawdzania statystyk odwiedzin na stronie
            internetowej. Dzięki nim łatwiej podejmować intratne ruchy, które odpowiadają na zapotrzebowanie klientów, a
            co za tym idzie – rozwijają daną działalność online. Sprawdź, co daje korzystanie z kodu Google Analytics i
            jak z niego skorzystać.
          </strong>
        </p>
        <h2>Na czym polega działanie Google Analytics?</h2>
        <p>
          Google Analytics to narzędzie służące do zbierania danych na temat ruchu na stronie internetowej, tj.
          pozyskiwaniu informacji o zachowaniu użytkowników. Ze statystyk dostępnych w Google Analytics można dowiedzieć
          się m.in.:
        </p>
        <ul>
          <li>Ilu użytkowników odwiedziło stronę.</li>
          <li>Ile czasu na niej spędzili.</li>
          <li>Z jakiego adresu na nią trafili (np. z wyszukiwarki, z Social Mediów, z innej strony internetowej).</li>
          <li>Z jakiej lokalizacji (z jakiego kraju, miasta).</li>
          <li>Jak zachowywali się na stronie (jakie treści ich interesowały, w jakiej kolejności je przeglądali).</li>
          <li>Ile strona uzyskała konwersji. </li>
        </ul>
        <p className="inserted-seo-link">
          Zdobądź wiedzę na temat tego,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            co to jest audyt SEO
          </a>{" "}
          strony internetowej. Dowiedz się już teraz!
        </p>
        <h2>W jaki sposób Google Analytics zbiera dane?</h2>
        <p>
          Głównymi źródłami raportów ze statystykami są: żądania http użytkowników, informacje o przeglądarce oraz pliki
          cookie. Zebrane dane trafią do serwerów Analytics w postaci listy parametrów.{" "}
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Co to jest ID śledzenia?</h2>
        <p>
          ID śledzenia, czy inaczej kod śledzenia, jest fragmentem kodu JavaScript, który odpowiada za gromadzenie
          informacji dotyczących ruchu na stronie, a następnie przesyła dane do aplikacji Google Analytics.{" "}
        </p>
        <p className="inserted-seo-link">
          Zleć wykonanie <a href="/">audytu SEO</a> strony i popraw jej widoczność w wyszukiwarce.
        </p>
        <p>
          Identyfikator śledzenia to automatycznie generowany ciąg znaków, dzięki któremu wiadomo, na które konto
          powinny być przesyłane statystyki. Kod śledzenia można uzyskać w aplikacji Analytics, a także w raporcie
          audytu SEO.
        </p>
        <h2>Jak skorzystać z Google Analytics? </h2>
        <p>
          Google Analytics jest darmową aplikacją. Aby z niej skorzystać, należy na stronie internetowej umieść
          odpowiedni tag. Są na to trzy sposoby:
        </p>
        <ul>
          <li>Automatyczny – poprzez zainstalowanie wtyczki Google Analytics.</li>
          <li>Automatyczny – poprzez aplikacje Google Tag Manager.</li>
          <li>Manualny (dla zaawansowanych) – poprzez dopisanie ID śledzenia do kodu strony internetowej. </li>
        </ul>
        <h2>Google Analytics a SEO</h2>
        <p>
          Google Analytics to przydatne narzędzie do analizowania i planowania działań SEO. Bez znajomości statystyk
          ruchu na stronie trudno stwierdzić, czy wprowadzone rozwiązania przynoszą korzyści. Ponadto dane analityczne
          pozwalają stwierdzić, nad którymi aspektami związanymi z funkcjonowaniem witryny warto jeszcze popracować; czy
          kampanie reklamowe przynoszą oczekiwane korzyści, jak prezentuje się jakość linków zewnętrznych do strony, czy
          jakie treści najbardziej interesują odwiedzających. W skrócie: Google Analytics pozwala na bieżąco badać
          popularność i kondycję strony internetowej.
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/google-analytics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
